<template>
  <el-main>
    <div class="topTips">
      <div>此为跨境电商PC网站的装修部分，PC端显示英文，请下面内容添加英文</div>
      <div v-if="website_url">
        网站地址：
        <span @click="toclickWeb">{{ website_url }}</span>
      </div>
    </div>
    <el-tabs style="margin-top: 10px" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础信息" name="1"></el-tab-pane>
      <el-tab-pane label="我们的使命" name="2"></el-tab-pane>
      <el-tab-pane label="推荐商家" name="3"></el-tab-pane>
    </el-tabs>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <div v-show="activeName === '1'">
        <el-form-item label="网站ico图标：" prop="ico">
          <div class="uploadIco">
            <div v-if="ruleForm.ico" class="icoShow">
              <img :src="ruleForm.ico" alt="" />
              <i class="el-icon-error delete" @click="ruleForm.ico = ''"></i>
            </div>
            <div class="icoBox" v-else>
              <i class="el-icon-plus"></i>
              <input ref="icoInput" type="file" accept=".ico" @change="getIcoFile" />
            </div>
            <div class="sizeTips">图片尺寸16*16px，大小不超过16kb</div>
          </div>
        </el-form-item>
        <el-form-item label="官网logo：" prop="logo">
          <ReadyUploadSource
            :path="ruleForm.logo"
            @getSource="val => (ruleForm.logo = val.path)"
            @removeThis="ruleForm.logo = ''"
            :showStyle="{
              width: '200px',
              height: '60px',
            }"
          ></ReadyUploadSource>
          <div class="sizeTips">建议图片尺寸200*60px，大小不超过1M</div>
        </el-form-item>
        <el-form-item label="公司介绍：" prop="company">
          <div class="company">
            <ReadyUploadSource
              :path="ruleForm.company_logo"
              @getSource="val => (ruleForm.company_logo = val.path)"
              @removeThis="ruleForm.company_logo = ''"
              :showStyle="{
                width: '200px',
                height: '120px',
              }"
            ></ReadyUploadSource>
            <div class="sizeTips">建议图片尺寸500*300px，大小不超过1M</div>
            <el-input v-model="ruleForm.company_name" placeholder="公司名称，建议不超过20个字符"></el-input>
            <el-input type="textarea" :rows="3" v-model="ruleForm.company_desc" placeholder="介绍内容，建议不超过1000个字符"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="公司地址：" prop="company_address">
          <el-input v-model="ruleForm.company_address"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="telphone">
          <el-input v-model="ruleForm.telphone"></el-input>
        </el-form-item>
        <el-form-item label="联系传真：" prop="fax">
          <el-input v-model="ruleForm.fax"></el-input>
        </el-form-item>
        <el-form-item label="联系邮箱：" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="备案信息：" prop="version_info">
          <el-input v-model="ruleForm.version_info"></el-input>
        </el-form-item>
        <el-form-item label="备案信息跳转网址：" prop="bak_links">
          <el-input v-model="ruleForm.bak_links"></el-input>
        </el-form-item>
        <el-form-item label="友情链接：" prop="links">
          <ReadyUploadSource
            :isMany="true"
            :manyPath="ruleForm.links"
            @changeOrder="list => (ruleForm.links = list)"
            @getSource="list => ruleForm.links.push(...list)"
            @removeThis="index => ruleForm.links.splice(index, 1)"
            :showStyle="{
              width: '200px',
              height: '80px',
            }"
          ></ReadyUploadSource>
          <div class="sizeTips">建议图片尺寸250*100px，大小不超过1M</div>
        </el-form-item>
      </div>
      <div v-show="activeName === '2'">
        <el-form-item v-for="(item, index) in 4" :key="index" :label="ruleForm[`mission` + index].label" :prop="'mission' + index">
          <div class="company">
            <ReadyUploadSource
              :path="ruleForm[`mission` + index].image"
              @getSource="val => (ruleForm[`mission` + index].image = val.path)"
              @removeThis="ruleForm[`mission` + index].image = ''"
              :showStyle="{
                width: '100px',
                height: '100px',
              }"
            ></ReadyUploadSource>
            <div class="sizeTips">建议图片尺寸300*300px，大小不超过1M</div>
            <el-input v-model="ruleForm[`mission` + index].title" placeholder="标题，建议不超过20个字符"></el-input>
            <el-input type="textarea" :rows="3" v-model="ruleForm[`mission` + index].content" placeholder="使命内容，建议不超过100个字符"></el-input>
          </div>
        </el-form-item>
      </div>
      <div v-show="activeName === '3'">
        <el-form-item label="推荐商家：" prop="recommend_sellers">
          <div v-for="(item, index) in ruleForm.recommend_sellers" :key="index" class="storeItem">
            <el-input disabled placeholder="请选择商家" v-model="item.store_name"></el-input>
            <el-button type="primary" @click="chooseRecommend(index)">选择</el-button>
            <img class="closeImg" @click="ruleForm.recommend_sellers.splice(index, 1)" v-if="index > 0" src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/close.png" alt="" />
            <span class="tips" v-else>建议不超过6个</span>
          </div>
          <el-button type="text" @click="addRecommendSellers">+ 添加推荐商家</el-button>
        </el-form-item>
      </div>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
    <!-- 选择商家弹框 -->
    <el-dialog title="选择商家" :visible.sync="pageFlag" width="52%">
      <div class="chooseShopBox">
        <div class="searchBox">
          <el-input v-model="searchShopName" placeholder="请输入商家名称"></el-input>
          <el-button type="primary" @click="getAllShopList(1)">搜索</el-button>
        </div>
        <div class="showAllList" @scroll="loadPageMore">
          <div class="showAllItem" v-for="(item, index) in allShopList" :key="index">
            <span>{{ item.store_name }}</span>
            <el-button type="text" @click="chooseStore(item)">选择</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import ReadyUploadSource from '@/components/readyUploadSource';
import _ from 'lodash';
export default {
  components: {
    Preservation,
    ReadyUploadSource,
  },
  data() {
    function checkImg(that, value, callback) {
      if (Array.isArray(value)) {
        if (!value.length) {
          that.activeName = '1';
          callback(new Error('请选择图片'));
        } else {
          callback();
        }
      } else {
        if (!value) {
          that.activeName = '1';
          callback(new Error('请选择图片'));
        } else {
          callback();
        }
      }
    }
    function checkContent(that, value, callback) {
      if (!value) {
        that.activeName = '1';
        callback(new Error('请输入内容'));
      } else {
        callback();
      }
    }
    function checkMission(that, value, callback) {
      if (!value.title) {
        callback(new Error('请输入使命标题'));
        that.activeName = '2';
      } else if (!value.content) {
        callback(new Error('请输入使命内容'));
        that.activeName = '2';
      } else if (!value.image) {
        callback(new Error('请输入使命图片'));
        that.activeName = '2';
      } else {
        callback();
      }
    }
    function checkCompany(that, value, callback) {
      if (!that.ruleForm.company_name) {
        callback(new Error('请填写公司名称'));
        that.activeName = '1';
      } else if (!that.ruleForm.company_desc) {
        callback(new Error('请填写介绍内容'));
        that.activeName = '1';
      } else if (!that.ruleForm.company_logo) {
        callback(new Error('请上传公司logo'));
        that.activeName = '1';
      } else {
        callback();
      }
    }
    return {
      activeName: '1',
      website_url: '',
      ruleForm: {
        ico: '',
        logo: '',
        email: '',
        fax: '',
        version_info: '',
        company_logo: '',
        company_name: '',
        company_desc: '',
        company_address: '',
        telphone: '',
        bak_links: '',
        // 公司愿景
        mission0: { label: '我们的使命一：', title: '', content: '', image: '' },
        mission1: { label: '我们的使命二：', title: '', content: '', image: '' },
        mission2: { label: '我们的使命三：', title: '', content: '', image: '' },
        mission3: { label: '我们的使命四：', title: '', content: '', image: '' },
        recommend_sellers: [{}],
        links: [],
      },
      rules: {
        ico: [{ required: true, validator: (rule, value, callback) => checkImg(this, value, callback), trigger: 'change' }],
        logo: [{ required: true, validator: (rule, value, callback) => checkImg(this, value, callback), trigger: 'change' }],
        links: [{ required: true, validator: (rule, value, callback) => checkImg(this, value, callback), trigger: 'change' }],
        company_address: [{ required: true, validator: (rule, value, callback) => checkContent(this, value, callback), trigger: 'blur' }],
        telphone: [{ required: true, validator: (rule, value, callback) => checkContent(this, value, callback), trigger: 'blur' }],
        fax: [{ required: true, validator: (rule, value, callback) => checkContent(this, value, callback), trigger: 'blur' }],
        email: [{ required: true, validator: (rule, value, callback) => checkContent(this, value, callback), trigger: 'blur' }],
        version_info: [{ required: true, validator: (rule, value, callback) => checkContent(this, value, callback), trigger: 'blur' }],
        bak_links: [{ required: true, validator: (rule, value, callback) => checkContent(this, value, callback), trigger: 'blur' }],
        mission0: [{ required: true, validator: (rule, value, callback) => checkMission(this, value, callback), trigger: 'blur' }],
        mission1: [{ required: true, validator: (rule, value, callback) => checkMission(this, value, callback), trigger: 'blur' }],
        mission2: [{ required: true, validator: (rule, value, callback) => checkMission(this, value, callback), trigger: 'blur' }],
        mission3: [{ required: true, validator: (rule, value, callback) => checkMission(this, value, callback), trigger: 'blur' }],
        company: [{ required: true, validator: (rule, value, callback) => checkCompany(this, value, callback), trigger: 'blur' }],
        recommend_sellers: [{ required: true }],
      },
      pageFlag: false,
      chooseIndex: -1,
      page: 1,
      rows: 10,
      total: 0,
      searchShopName: '',
      allShopList: [],
    };
  },
  created() {
    this.getSetInfo();
  },
  methods: {
    toclickWeb() {
      window.open(this.website_url);
    },
    async getIcoFile() {
      if (!this.$refs.icoInput.files.length) return;
      let file = this.$refs.icoInput.files[0];
      let suffix = file.name.substr(file.name.lastIndexOf('.')).toLowerCase();
      if (suffix !== '.ico' || file.size > 1024 * 1024 * 16) {
        this.$message.warning('请上传格式为ico,尺寸为16*16px,大小不超过16k的图片');
        return;
      }
      let url = window.URL || window.webkitURL;
      let img = new Image(); //手动创建一个Image对象
      img.src = url.createObjectURL(file); //创建Image的对象的url
      let { width, height } = await new Promise(resolve => {
        img.onload = function () {
          resolve({ width: img.width, height: img.height });
        };
      });
      if (width != 16 || height != 16) {
        this.$message.warning('请上传格式为ico,尺寸为16*16px,大小不超过16k的图片');
        return;
      }
      let formData = new FormData();
      formData.append('ico', file);
      this.$axios.uploadFile(this.$api.upload.addIco, formData).then(res => {
        if (res.code === 0) {
          this.$message.success('上传成功');
          this.ruleForm.ico = res.result.path;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 开启选择商家弹框
    chooseRecommend(index) {
      this.chooseIndex = index;
      this.getAllShopList(1);
      this.pageFlag = true;
    },
    addRecommendSellers() {
      let len = this.ruleForm.recommend_sellers.length;
      if (!this.ruleForm.recommend_sellers[len - 1].id) {
        this.$message.warning('请先将数据补充完整');
        return;
      }
      this.ruleForm.recommend_sellers.push({});
    },
    // 下滑加载更多
    loadPageMore(e) {
      if (e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight) {
        // 业务逻辑...
        if (this.total / this.rows > this.page) {
          this.page++;
          this.getAllShopList();
        }
      }
    },
    // 获取全部商家列表
    getAllShopList(style) {
      if (style) {
        this.page = 1;
        this.allShopList = [];
      }
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.searchShopName) obj.store_name = this.searchShopName;
      this.$axios.post(this.$api.store.storeList, obj).then(res => {
        if (res.code === 0) {
          this.allShopList = this.allShopList.concat(res.result.list);
          this.total = res.result.total_number;
        }
      });
    },
    // 选中商家
    chooseStore(data) {
      this.ruleForm.recommend_sellers[this.chooseIndex] = data;
      this.pageFlag = false;
    },
    Preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          delete obj.mission0;
          delete obj.mission1;
          delete obj.mission2;
          delete obj.mission3;
          if (!obj.recommend_sellers[obj.recommend_sellers.length - 1].id) obj.recommend_sellers.splice(obj.recommend_sellers.length - 1, 1);
          obj.mission = [this.ruleForm.mission0, this.ruleForm.mission1, this.ruleForm.mission2, this.ruleForm.mission3];
          this.$axios.post(this.$api.PCOfficialWebsite.setBaseInfo, obj).then(res => {
            if (res.code == 0) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getSetInfo() {
      this.$axios.post(this.$api.PCOfficialWebsite.getSetInfo).then(res => {
        if (res.code == 0) {
          if (!res.result.info.links) return;
          let { info } = res.result;
          for (const key in this.ruleForm) {
            if (key.indexOf('mission') === -1) this.ruleForm[key] = info[key];
          }
          if (info.mission) {
            for (let i = 0; i < 4; i++) {
              this.ruleForm[`mission` + i] = JSON.parse(info.mission)[i];
            }
            this.ruleForm.links = JSON.parse(info.links);
            this.ruleForm.recommend_sellers = JSON.parse(info.recommend_sellers);
          }
          this.website_url = info.website_url;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  font-size: 14px;
  padding-bottom: 80px;
  .topTips {
    width: 100%;
    background-color: #f2f2f2;
    padding: 25px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      color: #1466fd;
      cursor: pointer;
    }
    span:hover {
      text-decoration: underline;
    }
  }
  .el-form {
    .el-input,
    .el-textarea {
      width: 400px;
    }
    .sizeTips {
      color: #999999;
      font-size: 12px;
    }
    .company {
      display: flex;
      flex-direction: column;
      .el-input,
      .el-textarea {
        margin-bottom: 20px;
      }
    }
    .uploadIco {
      .icoShow {
        width: 80px;
        height: 80px;
        position: relative;
        border: 1px dashed #c0ccda;
        img {
          width: 100%;
          height: 100%;
        }
        &:hover {
          .delete {
            display: block !important;
          }
        }
        .delete {
          display: none;
          font-size: 24px !important;
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(50%, -50%);
          cursor: pointer;
        }
      }
      .icoBox {
        width: 80px;
        height: 80px;
        border: 1px dashed #c0ccda;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        i {
          font-size: 28px;
          color: #8c939d;
        }
        input {
          width: 100%;
          height: 100%;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .closeImg {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    cursor: pointer;
  }
  .tips {
    color: #999999;
    font-size: 12px;
    margin-left: 20px;
  }
  .chooseShopBox {
    .searchBox {
      display: flex;
      align-items: center;
    }
    .showAllList {
      height: 390px;
      width: 100%;
      overflow-y: auto;
      margin-top: 20px;
      .showAllItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
      }
      .showAllItem:hover {
        background-color: #faf5f5ef;
      }
    }
  }
  .storeItem {
    display: flex;
    align-items: center;
  }
  .storeItem:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
</style>
